import * as S from './styles';

import React from 'react';

export interface ButtonProps {
  width?: string;
  height?: string;
  bgColor?: string;
  color?: string;
  borderColor?: string;
  borderRadius?: string;
  font?: Record<string, string>;
  padding?: string;
  margin?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  className?: string;
}

const Button = ({ onClick, children, ...props }: ButtonProps) => {
  return (
    <S.DefaultButtonWrapper onClick={onClick} {...props}>
      {children}
    </S.DefaultButtonWrapper>
  );
};

export { Button };
