import styled, { css } from 'styled-components';
import { ValidityEnum } from '../../hooks/useTextInput';
import { Styles } from '../../styles/styles';

type WrapperStyledType = {
  disabled?: boolean;
  width?: string;
  isMini?: boolean;
  noMargin?: boolean;
};

export type InputValidityType = {
  validity: ValidityEnum;
};

export type InputButtonWrapperStyledType = {
  isButtonColor?: boolean;
  isButtonDisabled?: boolean;
  isMini?: boolean;
};

interface InputWrapperStyledType extends InputValidityType {
  isMini?: boolean;
}

export const validityToColor = (validity: ValidityEnum) => {
  switch (validity) {
    case ValidityEnum.CORRECT:
      return Styles.COLOR.CORRECT;
    case ValidityEnum.DANGER:
      return Styles.COLOR.DANGER;
    default:
      return Styles.COLOR.GREY_02;
  }
};

export const Wrapper = styled.div<WrapperStyledType>`
  ${({ noMargin, isMini }) =>
    !noMargin &&
    css`
      margin-bottom: ${isMini ? '23px' : '36px'};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
  width: ${({ width }) => (width ? width : '100%')};
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const InputWrapper = styled.div<InputWrapperStyledType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
  }
  width: 100%;
  height: ${({ isMini }) => (isMini ? '38px' : '52px')};
  border: 1px solid ${({ validity }) => validityToColor(validity)};
  border-radius: 10px;

  margin-bottom: 7px;
  padding: ${({ isMini }) => (isMini ? '0 17px' : '0 19px 0 33px')};

  :has(input:focus) {
    border: 1px solid ${({ theme }) => theme.COLOR.PRIMARY};
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  flex: 1 1;
  background-color: transparent;

  ::placeholder {
    ${({ theme }) => theme.FONT.B1};
    color: ${({ theme }) => theme.COLOR.GREY_02};
  }

  :focus {
    outline: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: ${({ theme }) => theme.COLOR.GREY_02};
  cursor: pointer;
  margin: 0 8px 0 15px;
`;

export const explanation = styled.p`
  ${({ theme }) => theme.FONT.B3};
  color: ${({ theme }) => theme.COLOR.GREY_02};
`;

export const InputButtonWrapper = styled.button<InputButtonWrapperStyledType>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ isMini }) => (isMini ? '67px' : '113px')};
  height: ${({ isMini }) => (isMini ? '24px' : '38px')};
  border: 1px solid
    ${({ isButtonColor, theme }) => !isButtonColor && theme.COLOR.PRIMARY};
  color: ${({ isButtonColor, theme }) =>
    isButtonColor ? theme.COLOR.WHITE : theme.COLOR.PRIMARY};
  border-radius: 50px;
  background-color: ${({ isButtonColor, theme }) =>
    isButtonColor && theme.COLOR.PRIMARY};

  ${({ theme }) => theme.FONT.B1};
  ${({ isMini }) =>
    isMini &&
    css`
      font-size: 12px;
    `}
  ${({ isButtonDisabled }) =>
    isButtonDisabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
  ${({ isButtonColor }) =>
    !isButtonColor &&
    css`
      font-weight: 600;
    `}
`;
