import styled from 'styled-components';
import Header from './Header';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <LayoutContainer>
      <Header />
      <BodyWrapper>{children}</BodyWrapper>
    </LayoutContainer>
  );
};

export default Layout;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => theme.WIDTH.LAYOUT};
  min-height: 100vh;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.COLOR.WHITE};
`;

const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
`;
