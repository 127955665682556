import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: unset;
    box-sizing: border-box;
    font-family: 'Pretendard';
    font-style: normal;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  :root {
      font-size: 16px;
      letter-spacing: -0.015em;
  }
  html,
  body {
    max-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.COLOR.GREY_01};
  }
  a,
  a:active,
  a:link,
  a:visited {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }
  button {
    background-color: unset;
    cursor: pointer;
    border: 0;
    padding: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  ul, ol, li, a, input, select, textarea {margin: 0;padding: 0; border: 0 none; }
  ul,
  ol,
  li {
    list-style: none;
  }
  a,
  img,
  button {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: transparent;
  }
  p {
    margin: 0;
  }
`;
