import {
  ChangeEvent,
  Dispatch,
  HTMLAttributes,
  ReactNode,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import * as S from './styles';
import { ValidityEnum } from '../../hooks/useTextInput';
import { Label } from './label';
import { ValidationMessage } from './validationMessage';
import { Icon } from '../Icon';

import HideIcon from '../../assets/icon/ic_hide.svg';
import ShowIcon from '../../assets/icon/ic_show.svg';
import CloseIcon from '../../assets/icon/ic_close.svg';
import { Styles } from '../../styles/styles';

type InputHandlerIconsProps = {
  type: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onClear: () => void;
};

export interface InputButtonProps extends S.InputButtonWrapperStyledType {
  children: ReactNode;
  className?: string;
  onClick: (() => void) | null;
}

interface TextInputProps extends HTMLAttributes<HTMLInputElement> {
  inputName?: string;
  label?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  type?: string;
  placeholder: string;
  explanation?: string;
  showVisibleOption?: boolean;
  onSetVisible?: () => void;
  validity?: ValidityEnum;
  validityText?: string;
  onFocusInput?: () => void;
  onFocusOut?: () => void;
  autoFocus?: boolean;
  pattern?: RegExp;
  buttonText?: string;
  buttonClassName?: string;
  onClickButton?: () => void;
  disabled?: boolean;
  isButtonDisabled?: boolean;
  isButtonColor?: boolean;
  isRequired?: boolean;
  isMini?: boolean;
  width?: string;
  noMargin?: boolean;
  hideHandler?: boolean;
}

const InputHandlerIcons = ({
  type,
  visible,
  setVisible,
  onClear,
}: InputHandlerIconsProps) => {
  return (
    <S.IconsWrapper>
      {type === 'password' && (
        <Icon
          src={visible ? ShowIcon : HideIcon}
          onClick={() => setVisible(!visible)}
          width={16}
          height={16}
          iconcolor={Styles.SVG_FILTER.GREY_02}
        />
      )}
      <S.ClearIconWrapper onClick={onClear}>
        <Icon
          src={CloseIcon}
          width={8}
          height={8}
          iconcolor={Styles.SVG_FILTER.WHITE}
        />
      </S.ClearIconWrapper>
    </S.IconsWrapper>
  );
};

const InputButton = ({
  className,
  onClick,
  children,
  isButtonDisabled,
  isButtonColor,
  isMini,
}: InputButtonProps) => {
  return (
    <S.InputButtonWrapper
      className={className}
      onClick={() => {
        if (onClick) onClick();
      }}
      isButtonDisabled={isButtonDisabled}
      isButtonColor={isButtonColor}
      isMini={isMini}
    >
      {children}
    </S.InputButtonWrapper>
  );
};

const TextInput = ({
  inputName,
  label,
  value,
  setValue,
  type = 'text',
  placeholder,
  explanation,
  validity = ValidityEnum.UNSET,
  validityText,
  onFocusInput,
  onFocusOut,
  autoFocus,
  pattern,
  buttonText,
  buttonClassName,
  onClickButton,
  disabled,
  isButtonDisabled,
  isButtonColor,
  isRequired,
  isMini,
  width,
  noMargin,
  hideHandler,
  onChange,
}: TextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [visible, setVisible] = useState(type !== 'password');

  const onClear = () => {
    setValue('');
    inputRef.current?.focus();
  };

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (pattern && !pattern.test(value)) {
      e.stopPropagation();
      return;
    }
    setValue(value);
    if (onChange) onChange(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      if (onClickButton) onClickButton();
    }
  };

  return (
    <S.Wrapper
      disabled={disabled}
      width={width}
      isMini={isMini}
      noMargin={noMargin}
    >
      {label && (
        <S.LabelWrapper>
          <Label isRequired={isRequired}>{label}</Label>
          <ValidationMessage validity={validity} validityText={validityText} />
        </S.LabelWrapper>
      )}
      <S.InputWrapper validity={validity} isMini={isMini}>
        <S.Input
          name={inputName}
          value={value}
          onChange={onChangeValue}
          placeholder={placeholder}
          type={visible && type === 'password' ? 'text' : type}
          onFocus={() => {
            if (onFocusInput) onFocusInput();
          }}
          onBlur={() => {
            if (onFocusOut) onFocusOut();
          }}
          autoFocus={!!autoFocus}
          ref={inputRef}
          disabled={disabled}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        {buttonText && onClickButton ? (
          <InputButton
            className={buttonClassName}
            onClick={disabled ? null : onClickButton}
            isButtonDisabled={isButtonDisabled}
            isButtonColor={isButtonColor}
            isMini={isMini}
          >
            {buttonText}
          </InputButton>
        ) : !hideHandler && value.length > 0 ? (
          <InputHandlerIcons
            type={type}
            visible={visible}
            setVisible={setVisible}
            onClear={onClear}
          />
        ) : null}
      </S.InputWrapper>
      {explanation && <S.explanation>{explanation}</S.explanation>}
    </S.Wrapper>
  );
};

export { TextInput };
