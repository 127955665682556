import * as S from './styles';

export interface IconStyleProps {
  src: string;
  animate?: boolean;
  width?: number;
  wrapperWidth?: string;
  wrapperHeight?: string;
  margin?: string;
  iconcolor?: Record<string, string>;
  bgColor?: string;
  onClick?: (e: any) => void;
  height?: number;
}

const Icon = ({
  src,
  animate,
  width,
  height,
  wrapperWidth,
  wrapperHeight,
  margin,
  iconcolor,
  bgColor,
  onClick,
}: IconStyleProps) => {
  return (
    <S.IconWrapper
      animate={animate}
      wrapperWidth={wrapperWidth}
      wrapperHeight={wrapperHeight}
      margin={margin}
      bgColor={bgColor}
      onClick={onClick}
    >
      <S.ImageStyle
        src={src}
        width={width}
        height={height}
        iconcolor={iconcolor}
      />
    </S.IconWrapper>
  );
};

export { Icon };
