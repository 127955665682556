import styled from 'styled-components';
import { IconStyleProps } from '.';

export const IconWrapper = styled.span<Partial<IconStyleProps>>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ wrapperWidth }) => wrapperWidth};
  height: ${({ wrapperHeight }) => wrapperHeight};
  margin: ${({ margin }) => margin};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ bgColor }) => !!bgColor && '50%'};
  cursor: ${({ onClick }) => onClick && 'pointer'};
`;

export const ImageStyle = styled.img<Pick<IconStyleProps, 'iconcolor'>>`
  ${({ iconcolor }) => iconcolor};
  object-fit: contain;
`;
