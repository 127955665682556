import axios from 'axios';
import { LOCAL_STORAGE_KEY } from '../constants/common';

const axiosInstance = axios.create({
  baseURL: 'https://api-family-home.sumcar.kr',
});

axiosInstance.interceptors.request.use((config) => {
  if (typeof window !== 'undefined') {
    const accessToken = window.localStorage.getItem(
      LOCAL_STORAGE_KEY.ACCESS_TOKEN
    );
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
});

export { axiosInstance };
