import { ReactNode } from 'react';
import styled from 'styled-components';

type LabelProps = {
  isRequired?: boolean;
  children: ReactNode;
};

const Label = ({ isRequired, children }: LabelProps) => {
  return (
    <Wrapper>
      {isRequired && <Required>*</Required>}
      <Text>{children}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 7px;
`;

const Text = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.COLOR.GREY_01};
`;

const Required = styled.p`
  color: ${({ theme }) => theme.COLOR.PRIMARY};
  position: absolute;
  left: -12px;
`;

export { Label };
