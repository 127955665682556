import { useCallback, useState } from 'react';
import { ValidatorType } from '../utils/validator';

export enum ValidityEnum {
  UNSET,
  CORRECT,
  DANGER,
}

const useTextInput = (validator: ValidatorType, defaultValue?: string) => {
  const [value, setValue] = useState(defaultValue ?? '');
  const [validity, setValidity] = useState(ValidityEnum.UNSET);
  const [validityText, setValidityText] = useState('');

  const clear = () => {
    setValidity(ValidityEnum.UNSET);
    setValidityText('');
  };

  const checkValidity = useCallback((newValue: string) => {
    const validationMessage = validator(newValue);

    if (!validationMessage) {
      clear();
    } else {
      setValidity(ValidityEnum.DANGER);
      setValidityText(validationMessage);
    }
  }, []);

  const onFocusOut = useCallback(
    () => checkValidity(value),
    [value, checkValidity]
  );

  const setAndCheckValidity = useCallback(
    (newValue: string) => {
      setValue(newValue);
      checkValidity(newValue);
    },
    [checkValidity]
  );

  return {
    value,
    setValue,
    validity,
    validityText,
    onFocus: clear,
    onFocusOut,
    setAndCheckValidity,
    checkValidity,
  };
};

export { useTextInput };
