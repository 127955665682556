import { HackleReactSDKClient } from '@hackler/react-sdk/lib/client';
import { BrowserRouter } from 'react-router-dom';
import HomeLayout from './layout/home';

export type PageProps = {
  hackleClient: HackleReactSDKClient;
};

const Router = ({ hackleClient }: PageProps) => {
  return (
    <BrowserRouter>
      <HomeLayout hackleClient={hackleClient} />
    </BrowserRouter>
  );
};

export default Router;
