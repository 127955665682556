import styled from 'styled-components';

const Header = () => {
  return (
    <HeaderWrapper>
      <Title>휴대폰 본인인증</Title>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 67px;
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  ${({ theme }) => theme.FONT.H4};
`;
