import styled from 'styled-components';

import CorrectIcon from '../../assets/icon/ic_check.svg';
import { InputValidityType, validityToColor } from './styles';
import { ValidityEnum } from '../../hooks/useTextInput';
import { Icon } from '../Icon';
import { Styles } from '../../styles/styles';

type ValidityIconProps = { validity: ValidityEnum };

type ValidationMessageProps = {
  validity: ValidityEnum;
  validityText?: string;
};

const ValidityIcon = ({ validity }: ValidityIconProps) => {
  return (
    <ValidityIconWrapper validity={validity}>
      {validity === ValidityEnum.DANGER ? (
        <p>!</p>
      ) : (
        <Icon
          src={CorrectIcon}
          width={8}
          height={8}
          iconcolor={Styles.SVG_FILTER.WHITE}
        />
      )}
    </ValidityIconWrapper>
  );
};

const ValidationMessage = ({
  validity,
  validityText,
}: ValidationMessageProps) => {
  if (validity === ValidityEnum.UNSET || !validityText) return null;

  return (
    <VailidityWrapper validity={validity}>
      <ValidityText>{validityText}</ValidityText>
      <ValidityIcon validity={validity} />
    </VailidityWrapper>
  );
};

const VailidityWrapper = styled.div<InputValidityType>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ validity }) => validityToColor(validity)};
  width: max-content;
  flex: 1;
  word-break: keep-all;
  margin-bottom: 4px;
  text-align: right;
`;

const ValidityText = styled.span`
  ${({ theme }) => theme.FONT.B3};
  margin-right: 4px;
`;

const ValidityIconWrapper = styled.div<{ validity: ValidityEnum }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  font-size: 8px;
  border-radius: 100%;
  margin-left: 4px;
  background-color: ${({ validity }) => validityToColor(validity)};
  color: ${({ theme }) => theme.COLOR.WHITE};

  & p {
    color: ${({ theme }) => theme.COLOR.WHITE};
  }
`;

export { ValidationMessage };
