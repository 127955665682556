import { ReactNode } from 'react';
import styled from 'styled-components';

type InformationFormTitleProps = {
  children: ReactNode;
  required?: boolean;
};

const InformationFormTitle = ({
  children,
  required,
}: InformationFormTitleProps) => {
  return (
    <Wrapper>
      <AreaTitle>{children}</AreaTitle>
      {required && <RequiredText>*필수 항목</RequiredText>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 14px;
  margin-bottom: 20px;
`;

const AreaTitle = styled.p`
  ${({ theme }) => theme.FONT.H5};
`;

const RequiredText = styled.p`
  ${({ theme }) => theme.FONT.B2};
  color: ${({ theme }) => theme.COLOR.PRIMARY};
`;

export default InformationFormTitle;
