import styled, { css } from 'styled-components';
import { ButtonProps } from '.';

export const DefaultButtonWrapper = styled.button<ButtonProps>`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      cursor: default;
    `}

  ${({ width }) =>
    !!width &&
    css`
      width: ${width};
    `};
  ${({ height }) =>
    !!height &&
    css`
      height: ${height};
    `};
  ${({ padding }) =>
    !!padding &&
    css`
      padding: ${padding};
    `};
  ${({ margin }) =>
    !!margin &&
    css`
      margin: ${margin};
    `};
  ${({ font }) =>
    !!font &&
    css`
      ${font}
    `};
  color: ${({ color, theme }) => (color ? color : theme.COLOR.WHITE)};
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.COLOR.PRIMARY};
  border: ${({ borderColor }) =>
    !!borderColor ? `1px solid ${borderColor}` : 'none'};
  border-radius: ${({ borderRadius }) =>
    !!borderRadius ? borderRadius : '24px'};
  outline: none;
  cursor: pointer;
  z-index: 1;
`;
