export const ALERT_MESSAGE_TYPE = {
  REQUEST_AUTH_NUMBER: '휴대전화로 인증번호가 전송되었어요!',
  SUCCESS_AUTH: '인증이 완료되었어요.',
  FAIL_AUTH: '인증번호를 다시 확인해주세요.',
  CHECK_AUTH: '인증번호를 입력하고 인증확인 버튼을 눌러주세요.',
  FAIL_ORDER_CONFIRMATION:
    '입력하신 정보를 다시 확인해주세요. 오류가 반복된다면 고객센터로 문의해주시기 바랍니다.',
  FAIL_GET_PRODUCT: '상품 정보를 불러오는데 실패했어요. 다시 시도해주세요.',
  CHECK_PHONE_NUMBER: '휴대전화 번호 형식을 확인해주세요.',
  NOT_ALLOWED_ACCESS: '잘못된 접근입니다.',
  NOT_ALLOWED_SEARCH_PARAM: '잘못된 검색 조건입니다.',
  INVALID_RESERVATION: '예약 정보가 유효하지 않습니다.',
  INVALID_REQUEST: '유효하지 않은 요청입니다.',
  ERROR_OCCURENCE: '오류가 발생했습니다.',
  ID_REQUIRED: '아이디를 입력해주세요.',
  PASSWORD_REQUIRED: '비밀번호를 입력해주세요',
  EXISTING_ACCOUNT:
    '이미 등록된 회원입니다. 다른 로그인 방식으로 다시 시도해주세요',
  PAID_RESERVATION:
    '이미 결제가 완료된 예약입니다.\n 주문 조회 페이지에서 예약을 조회해주세요',
} as const;

export const PHONE_AUTH_STATUS = {
  REQUEST: '인증 요청',
  SUCCESS: '인증 완료',
  REQUEST_AGAIN: '재요청',
  VALIDATE: '인증 확인',
};

export const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
};

export const HACKLE_BROWSER_SDK_KEY = {
  DEVELOPMENT: 'i2GAb4h8F9QtJ8smJu9wRpDmIXHhB9I4',
  PRODUCTION: 'b08JrjtsLQWOrF48Rd1YDfElQHfXkpc9',
};

export const NAME_REGEX = /^[a-zA-Zㄱ-힣ㆍᆢ]*$/;
export const BIRTH_DATE_LENGTH_REGEX = /^\d{0,6}$/;
export const BIRTH_DATE_REGEX =
  /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))$/;
export const GENDER_REGEX = /^[1234]{0,1}$/;
export const EMAIL_INPUT_ALLOW_REGEX = /^[a-zA-Z0-9!@#$%^&*.]*$/;
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,3}$/;
export const PHONE_NUMBER_REGEX = /^\d{0,3}-?\d{0,4}-?\d{0,4}$/;
export const PHONE_AUTH_REGEX = /^\d{0,6}$/;
