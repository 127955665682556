export const Styles = {
  FONT: {
    H1: {
      'font-size': '32px',
      'font-weight': '700',
      'line-height': '38px',
    },
    H2: {
      'font-size': '28px',
      'font-weight': '700',
      'line-height': '33px',
    },
    H3: {
      'font-size': '24px',
      'font-weight': '600',
      'line-height': '29px',
    },
    H4: {
      'font-size': '18px',
      'font-weight': '600',
      'line-height': '21px',
    },
    H5: {
      // TODO: 폰트 사이즈대로 순서 변경 필요
      'font-size': '20px',
      'font-weight': '600',
      'line-height': '23px',
    },
    B1: {
      'font-size': '16px',
      'font-weight': '400',
      'line-height': '27px',
    },
    B2: {
      'font-size': '14px',
      'font-weight': '300',
      'line-height': '25px',
    },
    B3: {
      'font-size': '12px',
      'font-weight': '300',
      'line-height': '20px',
    },
  },
  COLOR: {
    PRIMARY: '#7370ED',
    GREY_01: '#333333',
    GREY_02: '#7E7E7E',
    GREY_03: '#CCCCCC',
    GREY_04: '#EBEBEB',
    GREY_05: '#E7E7E7',
    RED: '#D70000',
    BLUE: '#0B25AE',
    DANGER: '#CB5A5A',
    WARNING: '#F6CA50',
    CORRECT: '#6CB771',
    WHITE: '#ffffff',
    BLACK: '#000000',
    NAVER_COLOR: '#03C75A',
    KAKAO_COLOR: '#FEE500',
    GREY_BG: '#F9F9F9',
  },
  SVG_FILTER: {
    GREY_01: {
      filter:
        'invert(16%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(95%) contrast(88%)',
    },
    GREY_02: {
      filter:
        'invert(53%) sepia(4%) saturate(0%) hue-rotate(216deg) brightness(92%) contrast(100%)',
    },
    DANGER: {
      filter:
        'invert(52%) sepia(7%) saturate(3210%) hue-rotate(312deg) brightness(88%) contrast(120%)',
    },
    WARNING: {
      filter:
        'invert(68%) sepia(78%) saturate(350%) hue-rotate(359deg) brightness(106%) contrast(93%)',
    },
    WHITE: {
      filter:
        'invert(100%) sepia(17%) saturate(0%) hue-rotate(88deg) brightness(112%) contrast(101%)',
    },
  },
  WIDTH: {
    LAYOUT: '360px',
  },
  HEIGHT: '201px',
};

export type StylesType = {
  FONT: {
    [K in keyof FontKeys]: {
      [key: string]: string;
    };
  };
  COLOR: {
    [K in keyof ColorKeys]: string;
  };
  WIDTH: {
    [K in keyof WidthKeys]: string;
  };
  HEIGHT: string;
};
type FontKeys = typeof Styles.FONT;

type ColorKeys = typeof Styles.COLOR;

type WidthKeys = typeof Styles.WIDTH;

type HeightKeys = typeof Styles.HEIGHT;
