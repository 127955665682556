import { axiosInstance } from './axiosInstance';

export type SendPhoneNumberType = {
  phoneNumber: string;
};

export type VerifyPhoneNumberType = {
  phoneNumber: string;
  code: string;
};

export const postSendPhoneNumber = async (
  body: SendPhoneNumberType
): Promise<SendPhoneNumberType> => {
  const response = await axiosInstance.post(
    `/verifications/phone-number/send`,
    body
  );
  return response.data;
};

export const postVerifyPhoneNumber = async (body: VerifyPhoneNumberType) => {
  const response = await axiosInstance.post(
    `/verifications/phone-number/verify`,
    body
  );
  return response.data;
};
