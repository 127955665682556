import styled from 'styled-components';
import {
  ALERT_MESSAGE_TYPE,
  NAME_REGEX,
  PHONE_AUTH_REGEX,
  PHONE_AUTH_STATUS,
  PHONE_NUMBER_REGEX,
} from '../../constants/common';
import { useTextInput } from '../../hooks/useTextInput';
import {
  isFilled,
  phoneAuthValidator,
  phoneValidator,
  reservationIdValidator,
} from '../../utils/validator';
import InformationFormTitle from './components/InfomationFormTitle';
import { TextInput } from '../../components/textInput';
import { useEffect, useState } from 'react';
import {
  postSendPhoneNumber,
  postVerifyPhoneNumber,
} from '../../services/verifications.api';
import { Button } from '../../components/button';
import { Styles } from '../../styles/styles';
import { useSearchParams } from 'react-router-dom';
import { PageProps } from '../../Router';

const HomeLayout = ({ hackleClient }: PageProps) => {
  const [searchParams] = useSearchParams();
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isPhoneAuthRequested, setIsPhoneAuthRequested] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const {
    value: reservationName,
    setValue: setReservationName,
    validity: reservationNameValidity,
    validityText: reservationNameValidityText,
    onFocus: onFocusReservationName,
    onFocusOut: onFocusOutReservationName,
  } = useTextInput(isFilled);

  const {
    value: reservationId,
    setValue: setReservationId,
    validity: reservationIdValidity,
    validityText: reservationIdValidityText,
    onFocus: onFocusReservationId,
    onFocusOut: onFocusOutReservationId,
  } = useTextInput(reservationIdValidator, searchParams.get('r') ?? '');

  const {
    value: reservationPhone,
    setValue: setReservationPhone,
    validity: reservationPhoneValidity,
    validityText: reservationPhoneValidityText,
    onFocus: onFocusReservationPhone,
    onFocusOut: onFocusOutReservationPhone,
  } = useTextInput(phoneValidator);

  const {
    value: phoneAuthNumber,
    setValue: setPhoneAuthNumber,
    validity: phoneAuthValidity,
    validityText: phoneAuthValidityText,
    onFocus: onFocusPhoneAuthNumber,
    onFocusOut: onFocusOutPhoneAuthNumber,
  } = useTextInput(phoneAuthValidator);

  const phoneAuthHandler = () => {
    setIsPhoneVerified(false);

    postSendPhoneNumber({ phoneNumber: reservationPhone })
      .then(() => {
        setIsPhoneAuthRequested(true);

        alert(ALERT_MESSAGE_TYPE.REQUEST_AUTH_NUMBER);
        if (!!phoneAuthNumber.length) {
          setPhoneAuthNumber('');
        }
      })
      .catch(() => alert(ALERT_MESSAGE_TYPE.CHECK_PHONE_NUMBER));
  };

  const phoneAuthVerificationHandler = () => {
    if (isPhoneVerified) {
      setIsPhoneAuthRequested(false);
      return;
    } else setIsPhoneAuthRequested(true);

    postVerifyPhoneNumber({
      phoneNumber: reservationPhone,
      code: phoneAuthNumber,
    })
      .then(() => {
        setIsPhoneVerified(true);

        alert(ALERT_MESSAGE_TYPE.SUCCESS_AUTH);
      })
      .catch(() => alert(ALERT_MESSAGE_TYPE.FAIL_AUTH));
  };

  const getButtonText = () => {
    if (isPhoneVerified) return PHONE_AUTH_STATUS.SUCCESS;

    if (isPhoneAuthRequested) return PHONE_AUTH_STATUS.REQUEST_AGAIN;
    else return PHONE_AUTH_STATUS.REQUEST;
  };

  const handleSelfCheckIn = () => {
    const reservationId = searchParams.get('r');
    hackleClient.track('familySelfCheckIn_click_button');
    window.location.href = `https://pub.seejeju.com/Checkin/?r=${reservationId}&i=fr78&c=115`;
  };

  useEffect(() => {
    if (
      !!reservationName.length &&
      reservationId.length === 7 &&
      reservationPhone.length === 11 &&
      !!isPhoneVerified
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  }, [reservationName, reservationId, reservationPhone, isPhoneVerified]);

  return (
    <Wrapper>
      <MessageWrapper>
        <MessageTitleText>
          셀프 체크인을 하기 전 <br /> 본인인증이 과정이 필요합니다
        </MessageTitleText>
        <MessageSubText>
          실제 운전자 정보와 다를 경우 추후에 셀프 체크인 등록이 취소될 수
          있습니다.
        </MessageSubText>
      </MessageWrapper>
      <TextInputWrapper>
        <InformationFormTitle>운전자 정보</InformationFormTitle>
        <TextInput
          inputName='reservation-number'
          type='tel'
          label='예약번호'
          placeholder=''
          explanation=''
          value={reservationId}
          setValue={setReservationId}
          isRequired
          isMini
          onClickButton={phoneAuthHandler}
          isButtonDisabled={reservationId.length !== 7}
          validity={reservationIdValidity}
          validityText={reservationIdValidityText}
          onFocusInput={onFocusReservationId}
          onFocusOut={onFocusOutReservationId}
        />
        <TextInput
          inputName='reservation-name'
          label='성명'
          placeholder='실명을 입력해주세요.'
          value={reservationName}
          setValue={setReservationName}
          isRequired
          isMini
          validity={reservationNameValidity}
          validityText={reservationNameValidityText}
          onFocusInput={onFocusReservationName}
          onFocusOut={onFocusOutReservationName}
          pattern={NAME_REGEX}
        />
        <TextInput
          inputName='reservation-phone-number'
          type='tel'
          label='휴대전화 번호'
          placeholder='-빼고 숫자만 입력해주세요.'
          explanation=''
          value={reservationPhone}
          setValue={setReservationPhone}
          isRequired
          isMini
          pattern={PHONE_NUMBER_REGEX}
          buttonText={
            // TODO: 로그인/비로그인에 따라 버튼 노출 작업
            getButtonText()
          }
          buttonClassName={
            getButtonText() === PHONE_AUTH_STATUS.REQUEST
              ? 'reserv2CertiNum'
              : undefined
          }
          onClickButton={phoneAuthHandler}
          isButtonDisabled={
            reservationPhone.length < 11 ||
            getButtonText() === PHONE_AUTH_STATUS.SUCCESS
          }
          isButtonColor={getButtonText() === PHONE_AUTH_STATUS.REQUEST}
          validity={reservationPhoneValidity}
          validityText={reservationPhoneValidityText}
          onFocusInput={onFocusReservationPhone}
          onFocusOut={onFocusOutReservationPhone}
        />
        <TextInput
          inputName='phone-number-verification'
          type='number'
          placeholder='숫자 6자리를 입력해주세요.'
          explanation='휴대폰으로 전송된 인증번호를 확인해주세요.'
          value={phoneAuthNumber}
          setValue={setPhoneAuthNumber}
          pattern={PHONE_AUTH_REGEX}
          onClickButton={phoneAuthVerificationHandler}
          buttonText={PHONE_AUTH_STATUS.VALIDATE}
          isButtonDisabled={phoneAuthNumber.length < 6}
          isButtonColor={true}
          validity={phoneAuthValidity}
          validityText={phoneAuthValidityText}
          onFocusInput={onFocusPhoneAuthNumber}
          onFocusOut={onFocusOutPhoneAuthNumber}
          disabled={
            getButtonText() === PHONE_AUTH_STATUS.REQUEST ||
            getButtonText() === PHONE_AUTH_STATUS.SUCCESS
          }
          isMini
        />
      </TextInputWrapper>
      <FillBlank />
      <Button
        width='320px'
        height='60px'
        font={Styles.FONT.H4}
        borderRadius='40px'
        onClick={handleSelfCheckIn}
        disabled={isButtonDisabled}
      >
        셀프 체크인 하기
      </Button>
    </Wrapper>
  );
};

export default HomeLayout;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 50px 0 30px 0;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 289px;
  gap: 16px;
`;

const MessageTitleText = styled.p`
  ${({ theme }) => theme.FONT.H3};
  margin-top: 30px;
`;

const MessageSubText = styled.p`
  ${({ theme }) => theme.FONT.B2};
  color: ${({ theme }) => theme.COLOR.GREY_01};
`;

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const FillBlank = styled.div`
  flex-grow: 1;
`;
