import ReactDOM from 'react-dom/client';
import Router from './Router';
import { ThemeProvider } from 'styled-components';
import { Styles } from './styles/styles';
import { GlobalStyle } from './styles/global.style';
import Layout from './components/common/Layout';
import { createInstance, HackleProvider } from '@hackler/react-sdk';
import { HACKLE_BROWSER_SDK_KEY } from './constants/common';

const hackleClient = createInstance(HACKLE_BROWSER_SDK_KEY.PRODUCTION);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={Styles}>
    <HackleProvider hackleClient={hackleClient}>
      <GlobalStyle />
      <Layout>
        <Router hackleClient={hackleClient} />
      </Layout>
    </HackleProvider>
  </ThemeProvider>
);
