export type ValidatorType = (value: string) => Nullable<string>;

export const isFilled: ValidatorType = (value) =>
  value.length > 0 ? null : FORM_MESSAGES.necessary;

export const reservationIdValidator: ValidatorType = (value) => {
  if (!value.length) return FORM_MESSAGES.necessary;
  if (value.length !== 7) return FORM_MESSAGES.checkRequired;
  return null;
};

export const phoneValidator: ValidatorType = (value) => {
  if (!value.length) return FORM_MESSAGES.necessary;
  if (value.length < 11) return FORM_MESSAGES.checkRequired;
  return null;
};

export const phoneAuthValidator: ValidatorType = (value) => {
  if (!value.length) return FORM_MESSAGES.necessary;
  if (value.length < 6) return FORM_MESSAGES.checkPhoneValidation;
  return null;
};

export const FORM_MESSAGES = {
  necessary: '필수 입력 항목이에요.',
  checkRequired: '확인이 필요해요.',
  checkPhoneValidation: '인증번호를 입력하고 인증확인 버튼을 눌러주세요.',
  checkForm: '형식을 확인해주세요.',
  checkDriverAge: '운전자 생년월일 입력을 확인해주세요.',
  notAllowedRent: '차량 렌트는 만 18세 이상부터 가능합니다.',
  notAllowedInsurance: '보험 가입 가능 나이가 아닙니다.',
  expiredVerification: '인증번호 유효시간이 만료되었습니다.',
};
